import { mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { userHasAccess } from '@/utils/utils'
import {
  mdiFileImage,
  mdiFilePdfBox,
  mdiFileWord,
  mdiMicrosoftExcel
} from '@mdi/js'
import RemoveSelectorDocuments from '../../Documents/RemoveSelectorDocuments'

export default {
  component: { RemoveSelectorDocuments },
  data () {
    return {
      dialogInstance: null
    }
  },
  mounted () {
    // Change apereance of dragged document in to folder => create html element with js

    // document.getElementById('docTableDocs').addEventListener('dragstart', function (e) {
    //   var img = document.createElement('img')
    //   img.src = 'http://kryogenix.org/images/hackergotchi-simpler.png'
    //   e.dataTransfer.setDragImage(img, 0, 0)
    // }, false)
  },
  methods: {
    ...mapActions({
      uploadDocument: 'documents/uploadDocument',
      updateDocument: 'documents/updateDocument',
      updateCompanyDocument: 'companyDocuments/updateCompanyDocument',
      updateGroupDocument: 'groupDocuments/updateGroupDocument',
      removeSharedDocument: 'documents/removeSharedDocument',
      removeCompanyDocument: 'companyDocuments/removeCompanyDocument',
      removeGroupDocument: 'groupDocuments/removeGroupDocument',
      deleteDocument: 'documents/deleteDocument',
      deleteCompanyDocument: 'companyDocuments/deleteCompanyDocument',
      deleteGroupDocument: 'groupDocuments/deleteGroupDocument',
      downloadDocument: 'documents/downloadDocument',
      createNewFolder: 'documents/createNewFolder',
      createNewCompanyFolder: 'companyDocuments/createNewCompanyFolder',
      createNewGroupFolder: 'groupDocuments/createNewGroupFolder',
      dragDocumentInToFolder: 'documents/dragDocumentInToFolder',
      changeDocumentFolder: 'documents/changeDocumentFolder',
      renameFolder: 'documents/renameFolder',
      deleteFolder: 'documents/deleteFolder',
      addToast: 'toasts/addToast'
    }),
    toggleDocument (doc) {
      const inListIndex = this.selected.findIndex(d => d._id === doc._id)
      if (inListIndex === -1) this.selected.push(doc)
      else this.selected.splice(inListIndex, 1)
    },
    getCheckboxVal (doc) {
      const inListIndex = this.selected.findIndex(d => d._id === doc._id)
      if (inListIndex === -1) return false
      else return true
    },
    activeButton (account, company, group, tab) {
      return userHasAccess(account, company, group, tab)
    },
    searchValue (index) {
      const el = document.getElementById(`searchTxt-${index}`)
      if (el) this.query = el.value
    },
    hoverRow (item) {
      this.hoveredDocumentRow = item
    },
    clearHover () {
      this.hoveredDocumentRow = false
    },
    // rightClickHandler (event, item) {
    //   document.oncontextmenu = function () { return false }
    //   this.showRightClickMenu = true
    //   this.menuPositionX = event.pageX
    //   this.menuPositionY = event.pageY
    //   this.actualRightClickedRow = item
    // },
    formatBytes (bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    fireActionEvent (item, actualRightClickedRow, actualTab) {
      console.log(item, actualRightClickedRow, actualTab, '???')
      if (item.action === 'open-doc') this.openFile(actualRightClickedRow)
      if (item.action === 'move-doc-to') this.moveDocToDialog(actualRightClickedRow)
      if (item.action === 'create-folder') this.createFolder()
      if (item.action === 'open-folder') this.openFolder(actualRightClickedRow)
      if (item.action === 'change-folder-name') this.changeFolderName(actualRightClickedRow)
      if (item.action === 'share-folder') this.onShareFolder(actualRightClickedRow)
      if (item.action === 'export-folder') this.onExportFolder(actualRightClickedRow)
      if (item.action === 'delete-folder') this.onDeleteFolder(actualRightClickedRow)
      if (item.action === 'change-name') this.updateRawData(actualRightClickedRow)
      if (item.action === 'export-doc') this.saveAsSingleFile(actualRightClickedRow)
      if (item.action === 'share-doc') this.shareItemAction(actualRightClickedRow, actualTab)
      if (item.action === 'assign-doc') this.addAssignAction(actualRightClickedRow)
      if (item.action === 'delete-doc') this.deleteDocumentAction(actualRightClickedRow)
    },
    rightClickMobile (contextmenu, incommingTableObj) {
      if (incommingTableObj && incommingTableObj.isMobile) {
        const obj = {
          item: incommingTableObj.item,
          showRightClickMenu: true
        }
        this.$emit('right-click-mobile', obj)
      } else {
        console.error('Something went wrong or app version is not mobile!')
      }
    },
    rightClick (info) {
      this.item = info.actualRightClickedRow
      this.showRightClickMenu = info.showRightClickMenu
      this.menuPositionX = info.menuPositionX
      this.menuPositionY = info.menuPositionY
    },
    closeMenu () {
      this.showRightClickMenu = false
      this.actualRightClickedRow = null
      this.contextMenuId = null
    },
    checkDisabledStatus (item) {
      if (item.text === this.$t('actions|share') && this.account.accountType !== 'lawyer') return true
      if (this.actualRightClickedRow && this.actualRightClickedRow.status === 'pending' && item.action !== 'delete-doc') {
        return true
      } else {
        return false
      }
    },
    moveDocToDialog (doc) {
      this.$refs.moveDialog.doc = doc
      this.$refs.moveDialog.activeFolder = this.activeFolderObject
      this.$refs.moveDialog.dialog = true
    },
    async moveDocumentHere (doc, folder, actualTab) {
      let _id = null
      let deleteDocBelogsToFolder = false
      if (!folder) deleteDocBelogsToFolder = true
      if (actualTab && actualTab.isMyTab) {
        _id = this.account._id
      }
      const docId = doc._id
      const folderId = folder ? folder._id : null
      const payload = {
        docId: docId,
        folderId: folderId,
        moveDocInToFolder: true,
        alreadyInFolder: !!this.actualFolder,
        deleteDocBelogsToFolder: deleteDocBelogsToFolder
      }
      try {
        await this.changeDocumentFolder({ _id, payload })
        this.dialog = false
      } catch (error) {
        console.error(error)
        this.addToast({
          title: 'Moving document was not successful',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async createFolder () {
      let _id = null
      let role = 'main'
      let folderId = null
      let useExactFunction = null
      const res = await this.$dialog.prompt({
        title: 'Add new folder name',
        text: 'Name',
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text',
          outlined: true,
          dense: true,
          class: 'mt-6'
        },
        actions: [
          {
            text: this.$t('actions|submit'), color: 'primary'
          }
        ]
      })
      if (!res) return
      if (this.activeFolder) {
        folderId = this.activeFolder
        role = 'subfolder'
      }
      if (this.actualTab.isMyTab) {
        _id = this.account._id
        useExactFunction = this.createNewFolder
      } else if (this.actualTab.isCompanyTab) {
        _id = this.company._id
        useExactFunction = this.createNewCompanyFolder
      } else if (this.actualTab.isGroupTab) {
        _id = this.actualTab.group._id
        useExactFunction = this.createNewGroupFolder
      }
      const payload = {
        tab: this.actualTab,
        folderName: res,
        role: role,
        folderId: folderId
      }
      try {
        await useExactFunction({ _id, payload })
      } catch (error) {
        console.error(error, 'Error in create folder')
      }
    },
    async onShareFolder (folder) {
      this.$refs.shareFolders.folder = folder
      this.$refs.shareFolders.activeFolder = this.activeFolderObject
      this.$refs.shareFolders.folderId = folder._id
      this.$refs.shareFolders.folders = this.folders
      this.$refs.shareFolders.actualTab = this.actualTab
      this.$refs.shareFolders.dialog = true
    },
    async onExportFolder (folder) {
      this.loading = true
      let zipNameInput = 'lavvira-zip'
      let zip = new JSZip()
      let _subfolder
      let path
      let file
      const mainFolder = zip.folder(folder.raw.originalname)
      if (this.actualTab && this.actualTab.isMyTab && folder) {
        for (let i = 0; i < folder.documents.length; i++) {
          path = `/downloadDocument/${typeof folder.documents[i].createdBy === 'object' ? folder.documents[i].createdBy._id : folder.documents[i].createdBy}/${folder.documents[i].raw.filename}`
          file = await this.downloadDocument(path)
          mainFolder.file(folder.documents[i].raw.originalname, new Blob([file]))
        }
      } else {
        for (let i = 0; i < folder.documents.length; i++) {
          path = `/downloadDocument/${folder.documents[i].createdBy._id}/${folder.documents[i].raw.filename}`
          file = await this.downloadDocument(path)
          mainFolder.file(folder.documents[i].raw.originalname, new Blob([file]))
        }
      }
      const getFolderZipped = async (folder, parentFolder) => {
        _subfolder = parentFolder.folder(folder.raw.originalname)
        for (let i = 0; i < folder.documents.length; i++) {
          if (typeof folder.documents[i] === 'string') {
            const doc = this.documents.find(d => d._id === folder.documents[i]) ||
            this.companyDocuments.find(d => d._id === folder.documents[i]) ||
            this.groupDocuments.find(d => d._id === folder.documents[i]) ||
            this.sharedWithMeDocuments.find(d => d._id === folder.documents[i])
            path = `/downloadDocument/${typeof doc.createdBy === 'object' ? doc.createdBy._id : doc.createdBy}/${doc.raw.filename}`
            file = await this.downloadDocument(path)
            _subfolder.file(folder.documents[i].raw.originalname, new Blob([file]))
          } else if (typeof folder.documents[i] === 'object') {
            path = `/downloadDocument/${typeof folder.documents[i].createdBy === 'object' ? folder.documents[i].createdBy._id : folder.documents[i].createdBy}/${folder.documents[i].raw.filename}`
            file = await this.downloadDocument(path)
            _subfolder.file(folder.documents[i].raw.originalname, new Blob([file]))
          }
        }
        return _subfolder
      }
      const handleSubFolders = async (subfolders, parentFolder) => {
        let zippedFolder
        for (let idx = 0; idx < subfolders.length; idx++) {
          let subfolder
          if (typeof subfolders[idx] === 'object') {
            subfolder = subfolders[idx]
          } else if (typeof subfolders[idx] === 'string') {
            subfolder = this.folders.find(sf => sf._id === subfolders[idx]) ||
            this.companyFolders.find(csf => csf._id === subfolders[idx]) ||
            this.groupFolders.find(gsf => gsf._id === subfolders[idx])
          }
          zippedFolder = await getFolderZipped(subfolder, parentFolder)
          if (subfolder.subfolders && subfolders.length) {
            handleSubFolders(subfolder.subfolders, zippedFolder)
          }
        }
      }
      await handleSubFolders(folder.subfolders, mainFolder)

      await zip.generateAsync({ type: 'blob' })
        .then(function (content) {
          // see FileSaver.js
          saveAs(content, zipNameInput)
        }).catch((err) => {
          console.error('createZip', err)
        })
      this.loading = false
    },
    async onDeleteFolder (folder) {
      let _id = null
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure')
      })
      if (!res) return
      if (this.actualTab.isMyTab) {
        _id = this.account._id
      } else if (this.actualTab.isCompanyTab) {
        _id = this.actualTab.company._id
      } else if (this.actualTab.isGroupTab) {
        _id = this.actualTab.group._id
      }
      const payload = {
        folderId: folder._id,
        deleteFolder: true
      }
      try {
        await this.deleteFolder({ _id, payload })
      } catch (error) {
        this.addToast({
          title: 'Deleting folder was not successful',
          color: 'white',
          snackbarColor: 'error'
        })
        console.error(error)
      }
    },
    async changeFolderName (folder) {
      let _id = null
      if (this.actualTab.isMyTab) {
        _id = this.account._id
      }
      const res = await this.$dialog.prompt({
        title: 'Rename folder',
        text: 'Add new folder name',
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text',
          outlined: true,
          dense: true,
          class: 'mt-6'
        },
        actions: [
          {
            text: this.$t('actions|submit'), color: 'primary'
          }
        ]
      })
      if (!res) return
      try {
        const payload = {
          newFolderName: res,
          folderId: folder._id
        }
        await this.renameFolder({ _id, payload })
      } catch (error) {
        console.error(error)
        this.addToast({
          title: 'Renaming folder was not successful',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    openFolder (folder) {
      if (this.$route.path.indexOf('folders') !== -1) this.$router.push(`${folder._id}`)
      else this.$router.push(`/documents/folders/${folder._id}`)
    },
    async updateRawData (document, input) {
      let res = ''
      if (this.$vuetify && this.$vuetify.breakpoint.mobile && !input) {
        this.changeName = true
        this.selectedDocForNameChange = document
      } else {
        if (input) {
          res = input
        } else {
          res = await this.$dialog.prompt({
            title: this.$t('documents|name'),
            text: this.$t('actions|add_new_document_name'),
            textField: {
              // Any addtional props/attrs that will be binded to v-text-field component
              type: 'text',
              outlined: true,
              dense: true,
              class: 'mt-6'
            },
            actions: [
              {
                text: this.$t('actions|submit'), color: 'primary'
              }
            ]
          })
        }
        if (!res) return
        this.changeName = false
        this.selectedDocForNameChange = null
        if (this.actualTab.isMyTab && !this.actualTab.isCompanyTab && !this.actualTab.isGroupTab) {
          await this.updateDocument({
            _id: document._id,
            payload: {
              raw: {
                ...document.raw,
                originalname: res
              }
            }
          })
        } else if (this.actualTab.isCompanyTab && !this.actualTab.isMyTab && !this.actualTab.isGroupTab) {
          await this.updateCompanyDocument({
            _id: document._id,
            payload: {
              raw: {
                ...document.raw,
                originalname: res
              }
            }
          })
        } else if (this.actualTab.isGroupTab && !this.actualTab.isCompanyTab && !this.actualTab.isMyTab) {
          await this.updateGroupDocument({
            _id: document._id,
            payload: {
              raw: {
                ...document.raw,
                originalname: res
              }
            }
          })
        }
      }
    },
    getStatus (selected) {
      let res
      selected.forEach(doc => {
        if (doc.status === 'pending') res = true
        else res = false
      })
      return res
    },
    getIconType (fileType, docName) {
      switch (fileType) {
      case 'application/pdf':
        return mdiFilePdfBox
      case 'image/jpeg':
        return mdiFileImage
      case 'image/png':
        return mdiFileImage
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return mdiFileWord
      case 'application/msword':
        return mdiFileWord
      case 'application/rtf':
        return mdiFileWord
      case 'application/vnd.ms-word.document.macroenabled.12':
        return mdiFileWord
      case 'application/msword-template':
        return mdiFileWord
      case 'application/vnd.oasis.opendocument.text':
        return mdiFileWord
      case 'application/vnd.oasis.opendocument.text-template':
        return mdiFileWord
      case 'application/vnd.oasis.opendocument.text-flat-xml':
        return mdiFileWord
      case 'application/vnd.ms-excel':
        return mdiMicrosoftExcel
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return mdiMicrosoftExcel
      case 'application/vnd.oasis.opendocument.spreadsheet':
        return mdiMicrosoftExcel
      case 'application/octet-stream':
        return 'mdi-file'
      default:
        return 'mdi-file'
      }
    },
    getItemColor (fileType) {
      switch (fileType) {
      case 'application/pdf':
        return '#c11e07'
      case 'image/jpeg':
        return '#ffbd2e'
      case 'image/png':
        return 'blue'
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '#2372ba'
      case 'application/msword':
        return '#2372ba'
      case 'application/rtf':
        return '#2372ba'
      case 'application/vnd.ms-word.document.macroenabled.12':
        return '#2372ba'
      case 'application/msword-template':
        return '#2372ba'
      case 'application/vnd.oasis.opendocument.text':
        return '#2372ba'
      case 'application/vnd.oasis.opendocument.text-template':
        return '#2372ba'
      case 'application/vnd.oasis.opendocument.text-flat-xml':
        return '#2372ba'
      case 'application/vnd.ms-excel':
        return 'green darken-3'
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'green darken-3'
      case 'application/vnd.oasis.opendocument.spreadsheet':
        return 'green darken-3'
      case 'application/octet-stream':
        return 'grey lighten-1'
      default:
        return 'grey'
      }
    },
    getSvg (fileType, docName) {
      if (docName && docName.includes('.pages')) return require(`@/assets/pages.svg`)
      if (docName && docName.includes('.numbers')) return require(`@/assets/numbers.png`)
      if (fileType && fileType === 'application/vnd.apple.pages') return require(`@/assets/pages.svg`)
      if (fileType && fileType === 'application/vnd.apple.numbers') return require(`@/assets/numbers.png`)
    },
    getOctetValue (docName) {
      if (docName && docName.includes('.pages')) return require(`@/assets/pages.svg`)
      if (docName && docName.includes('.numbers')) return require(`@/assets/numbers.png`)
    },
    isIcon (fileType, docName) {
      let rule = true
      if (fileType === 'application/vnd.apple.pages' || fileType === 'application/vnd.apple.numbers') {
        rule = false
      } else if (fileType === 'application/octet-stream') {
        if (docName && docName.includes('.pages')) rule = false
        if (docName && docName.includes('.numbers')) rule = false
      }
      return rule
    },
    openFile (document, location) {
      if (!this.$refs.fileView || !document || !document.raw) return
      if (location && location === 'mobile') {
        this.$router.push({
          path: `/file-view-mobile/${document._id}`
        })
      } else {
        if (document.parsed) {
          let path = `/downloadDocument/${document.raw.pdf_path.replace('files/', '')}`
          this.$refs.fileView.openFile({
            path,
            mimetype: document.raw.mimetype,
            filename: document.raw.originalname,
            json: document.parsed.text_json,
            extractedData: document.info.content[0].lavvira_labels,
            _id: document._id,
            doc: document,
            parsed: true
          })
        } else {
          let path = `/downloadDocument/${document.raw.pdf_path.replace('files/', '')}`
          this.$refs.fileView.openFile({
            path,
            mimetype: document.raw.mimetype,
            filename: document.raw.originalname,
            parsed: false,
            doc: document
          })
        }
      }
    },
    async downloadDocument (path) {
      const { data } = await this.$axios({
        url: path,
        method: 'GET',
        responseType: 'blob' // important
      })
      return data
    },
    async saveAsSingleFile (document) {
      const slicedPath = document.raw.path.slice(6, document.raw.path.length)
      const path = `/downloadDocument/${slicedPath}`
      // const path = `/downloadDocument/${document.createdBy}/${document.raw.filename}`
      const file = await this.downloadDocument(path)
      saveAs(file, document.raw.originalname)
    },
    async saveAsMultipleFiles () {
      this.loading = true
      let zipNameInput = 'lavvira-zip'
      let zip = new JSZip()
      let docs = zip.folder('documents')
      for (let i = 0; i < this.selected.length; i++) {
        const document = this.selected[i]
        const slicedPath = document.raw.path.slice(6, this.selected[i].raw.path.length)
        const path = `/downloadDocument/${slicedPath}`
        const file = await this.downloadDocument(path)
        docs.file(document.raw.originalname, new Blob([file]))
      }
      await zip.generateAsync({ type: 'blob' })
        .then(function (content) {
          // see FileSaver.js
          saveAs(content, zipNameInput)
        }).catch((err) => {
          console.error('createZip', err)
        })
      this.loading = false
    },
    addDocumentAction (tab, activeFolderObject) {
      EventBus.$emit('dialog-add-document-from-docs-table', tab, activeFolderObject)
    },
    addAssignAction (document) {
      EventBus.$emit('dialog-assign-document', document, this.actualTab)
    },
    addAssignActionMultiple () {
      EventBus.$emit('dialog-assign-document-multiple', this.selected, this.actualTab)
    },
    shareItemAction (document, tab) {
      EventBus.$emit('dialog-share-document', document, tab)
    },
    shareMultipleDocs (selectedDocs, tab) {
      EventBus.$emit('dialog-share-multiple-docs', selectedDocs, tab)
    },
    addExportAction (filteredDocuments) {
      EventBus.$emit('dialog-export-document', filteredDocuments, 'from-documents-section')
    },
    changeDocumentTypeAction (document) {
      EventBus.$emit('dialog-change-document-type', document)
    },
    async assignDocument (doc) {
      const documentClient = this.documentClient || this.clients[0]._id
      await this.updateDocument({
        _id: doc._id,
        payload: {
          clients: [documentClient],
          cases: this.documentCases
        }
      })
    },
    async unassignDocument (document, _case) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure') + ` ${this.$t('warning|unassign_document_from_the_case_itself')}`
      })
      if (res) {
        const payload = {
          unassignDocumentFromCase: true,
          caseId: _case._id
        }
        try {
          await this.updateDocument({ _id: document._id, payload })
        } catch (error) {
          console.error(error, 'Error unassign document')
        }
      }
    },
    async unassignDocumentClient (document, clientName) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure') + ` ${this.$t('warning|unassign_document_from_client_with_name')} ${clientName}`
      })
      if (res) {
        const payload = {
          unassignDocumentFromClient: true,
          clientId: document.clients[0]._id
        }
        try {
          await this.updateDocument({ _id: document._id, payload })
        } catch (error) {
          console.error(error, 'Error unassign document client')
        }
      }
    },
    closeDialogInstance () {
      this.dialogInstance.close()
    },
    async deleteDocumentAction (document, location) {
      if (
        location && location === 'from-document-card' &&
        document.sharedWith.accounts.length +
        document.sharedWith.company.length +
        document.sharedWith.groups.length > 1
      ) {
        const removeSharedDocument = this.removeSharedDocument
        const account = this.account
        const company = this.company
        const remove = this.$t('actions|remove')
        const from = this.$t('common|from')
        const select = this.$t('actions|select')
        const required = this.$t('fields|required')
        const myDocuments = this.$t('documents|my_documents')
        // we are using here a $dialog.show(vue-component)
        // 1. define props of the vue component
        RemoveSelectorDocuments.props = {
          documentProp: {
            type: Object,
            default: () => ({ document })
          },
          accountProp: {
            type: Object,
            default: () => ({ account })
          },
          companyProp: {
            type: Object,
            default: () => ({ company })
          },
          updateDocumentFunction: {
            type: Function,
            default: removeSharedDocument
          },
          $t_remove: {
            type: String,
            default: remove
          },
          $t_from: {
            type: String,
            default: from
          },
          $t_select: {
            type: String,
            default: select
          },
          $t_required: {
            type: String,
            default: required
          },
          $t_myDocuments: {
            type: String,
            default: myDocuments
          },
          onClick: Function
        }
        // 2. render dialog
        const dialogInstance = await this.$dialog.show(RemoveSelectorDocuments, { onClick: this.closeDialogInstance.bind(this.closeDialogInstance) })
        this.dialogInstance = dialogInstance
      }
      if (
        location &&
        location === 'from-document-card' &&
        document.sharedWith.accounts.length +
        document.sharedWith.company.length +
        document.sharedWith.groups.length <= 1
      ) {
        const res = await this.$dialog.confirm({
          text: this.$t('warning|warning_delete_document'),
          title: this.$t('common|warning')
        })
        if (!res) return
        try {
          await this.deleteDocument({ document })
        } catch (e) {
          console.error(e, 'error')
        }
      }
      // if (
      //   location &&
      //   location === 'from-folder' &&
      //   document.sharedWith.accounts.length +
      //   document.sharedWith.company.length +
      //   document.sharedWith.groups.length <= 1
      // ) {
      //   try {
      //     await this.deleteDocument({ document })
      //   } catch (e) {
      //     console.error(e, 'error')
      //   } finally {
      //     const res = 'success'
      //     // eslint-disable-next-line no-unsafe-finally
      //     return res
      //   }
      // }
      if (this.actualTab && this.actualTab.isSharedWithMe) {
        const res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: this.$t('warning|remove_this_document')
        })
        if (!res) return
        const payload = {
          removeFromShared: this.account._id,
          account: true
        }
        try {
          await this.removeSharedDocument({
            _id: document._id,
            payload
          })
        } catch (e) {
          console.error(e, 'error')
        }
      }
      if (!location && this.actualTab.isCompanyTab && this.company && document.sharedWith.accounts.length + document.sharedWith.company.length + document.sharedWith.groups.length > 1) {
        const res = await this.$dialog.confirm({
          text: `${this.$t('warning|remove_this_documents_from')} ${this.company.companyData.companyName} ${this.$t('documents|documents').toLowerCase()}?`,
          title: this.$t('common|warning')
        })
        if (!res) return
        const payload = {
          removeFromShared: this.company._id,
          company: true
        }
        try {
          await this.removeCompanyDocument({
            _id: document._id,
            payload
          })
        } catch (e) {
          console.error(e, 'error')
        }
      } else if (!location && this.actualTab.isGroupTab && this.group && document.sharedWith.accounts.length + document.sharedWith.company.length + document.sharedWith.groups.length > 1) {
        const res = await this.$dialog.confirm({
          text: `${this.$t('warning|remove_this_documents_from')} ${this.group.groupName} ${this.$t('documents|documents').toLowerCase()}?`,
          title: this.$t('common|warning')
        })
        if (!res) return
        const payload = {
          removeFromShared: this.group._id,
          group: true
        }
        try {
          await this.removeGroupDocument({
            _id: document._id,
            payload
          })
        } catch (e) {
          console.error(e, 'error')
        }
      } else if (!location && this.actualTab.isMyTab && !this.actualTab.isCompanyTab && !this.actualTab.isGroupTab && document.sharedWith.accounts.length + document.sharedWith.company.length + document.sharedWith.groups.length > 1) {
        const res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: this.$t('warning|remove_this_document')
        })
        if (!res) return
        const payload = {
          removeFromShared: this.account._id,
          account: true
        }
        try {
          await this.removeSharedDocument({
            _id: document._id,
            payload
          })
        } catch (e) {
          console.error(e, 'error')
        }
      } else if (!location && document.sharedWith.accounts.length + document.sharedWith.company.length + document.sharedWith.groups.length <= 1) {
        const res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: this.$t('warning|warning_delete_document')
        })
        if (!res) return
        try {
          if (this.actualTab.isMyTab && !this.actualTab.isCompanyTab && !this.actualTab.isGroupTab) {
            await this.deleteDocument({ document })
          } else if (this.actualTab.isCompanyTab && !this.actualTab.isMyTab && !this.actualTab.isGroupTab) {
            await this.deleteCompanyDocument({ document })
          } else if (this.actualTab.isGroupTab && !this.actualTab.isCompanyTab && !this.actualTab.isMyTab) {
            await this.deleteGroupDocument({ document })
          }
        } catch (e) {
          console.error(e, 'error')
        }
      }
    },
    async deleteMultipleDocuments () {
      let documentsToBeDeleted = []
      let documentsToBeRemoved = []
      for (let i = 0; i < this.selected.length; i++) {
        const document = this.selected[i]
        if (document.sharedWith.accounts.length + document.sharedWith.company.length + document.sharedWith.groups.length > 1) {
          documentsToBeRemoved.push(document)
        } else {
          documentsToBeDeleted.push(document)
        }
      }
      if (this.isCompanyTab && this.company && documentsToBeRemoved.length >= 1) {
        const mekeTranslation = (language) => {
          if (language === 'de') {
            return `${this.$t('warning|are_you_sure_to_remove_doc_from')} ${this.company.companyData.companyName} entfernen?`
          } else {
            return `${this.$t('warning|are_you_sure_to_remove_doc_from')} ${this.company.companyData.companyName}?`
          }
        }
        const res = await this.$dialog.confirm({
          text: mekeTranslation(this.selectedLanguage),
          title: this.$t('common|warning')
        })
        if (!res) return
        for (let i = 0; i < documentsToBeRemoved.length; i++) {
          const doc = documentsToBeRemoved[i]
          const payload = {
            removeFromShared: this.company._id,
            company: true
          }
          try {
            await this.removeCompanyDocument({
              _id: doc._id,
              payload
            })
          } catch (e) {
            console.error(e, 'error')
          }
        }
      }
      if (this.isCompanyTab && this.company && documentsToBeDeleted.length >= 1) {
        const res = await this.$dialog.confirm({
          text: `${this.$t('warning|irrevocably_delete_these_documents_from')} ${this.company.companyData.companyName} ${this.$t('documents|documents').toLowerCase()}?`,
          title: this.$t('common|warning')
        })
        if (!res) return
        for (let i = 0; i < documentsToBeDeleted.length; i++) {
          const document = documentsToBeDeleted[i]
          try {
            await this.deleteCompanyDocument({ document })
          } catch (e) {
            console.error(e, 'error')
          }
        }
      }
      if (this.isGroupTab && this.group && documentsToBeRemoved.length >= 1) {
        const res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: `${this.$t('warning|remove_this_documents_from')} ${this.group.groupName} ${this.$t('documents|documents').toLowerCase()}?`
        })
        if (!res) return
        for (let i = 0; i < documentsToBeRemoved.length; i++) {
          const doc = documentsToBeRemoved[i]
          const payload = {
            removeFromShared: this.group._id,
            group: true
          }
          try {
            await this.removeGroupDocument({
              _id: doc._id,
              payload
            })
          } catch (e) {
            console.error(e, 'error')
          }
        }
      }
      if (this.isGroupTab && this.group && documentsToBeDeleted.length >= 1) {
        const res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: `${this.$t('warning|irrevocably_delete_these_documents_from')} ${this.group.groupName} ${this.$t('documents|documents').toLowerCase()}?`
        })
        if (!res) return
        for (let i = 0; i < documentsToBeDeleted.length; i++) {
          const document = documentsToBeDeleted[i]
          try {
            await this.deleteGroupDocument({ document })
          } catch (e) {
            console.error(e, 'error')
          }
        }
      }
      if (this.actualTab.isMyTab && documentsToBeRemoved.length >= 1) {
        const res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: this.$t('warning|remove_documents_from_your_documents')
        })
        if (!res) return
        for (let i = 0; i < documentsToBeRemoved.length; i++) {
          const doc = documentsToBeRemoved[i]
          const payload = {
            removeFromShared: this.account._id,
            account: true
          }
          try {
            await this.removeSharedDocument({
              _id: doc._id,
              payload
            })
          } catch (e) {
            console.error(e, 'error')
          }
        }
      }
      if (this.actualTab.isMyTab && documentsToBeDeleted.length >= 1) {
        const res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: this.$t('warning|irrevocably_delete_documents')
        })
        if (!res) return
        for (let i = 0; i < documentsToBeDeleted.length; i++) {
          const document = documentsToBeDeleted[i]
          try {
            await this.deleteDocument({ document })
          } catch (e) {
            console.error(e, 'error')
          }
        }
      }
    },
    async pinToDashboard (document) {
      const payload = {
        setPin: this.account._id
      }
      try {
        if (this.isMyTab && !this.isCompanyTab && !this.isGroupTab) {
          await this.updateDocument({
            _id: document._id,
            payload
          })
        } else if (this.isCompanyTab && !this.isMyTab && !this.isGroupTab) {
          await this.updateCompanyDocument({
            _id: document._id,
            payload
          })
        } else if (this.isGroupTab && !this.isCompanyTab && !this.isMyTab) {
          await this.updateGroupDocument({
            _id: document._id,
            payload
          })
        }
        this.addToast({
          title: this.$t('message|document_pin_dashboard_success'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('message|document_pin_dashboard_fail'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async removePinFromDashboard (document) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning')
      })
      if (!res) return
      const payload = {
        removePin: this.account._id
      }
      try {
        if (this.isMyTab && !this.isCompanyTab && !this.isGroupTab) {
          await this.updateDocument({
            _id: document._id,
            payload
          })
        } else if (this.isCompanyTab && !this.isMyTab && !this.isGroupTab) {
          await this.updateCompanyDocument({
            _id: document._id,
            payload
          })
        } else if (this.isGroupTab && !this.isCompanyTab && !this.isMyTab) {
          await this.updateGroupDocument({
            _id: document._id,
            payload
          })
        } else {
          await this.updateDocument({
            _id: document._id,
            payload
          })
        }
        this.addToast({
          title: this.$t('message|document_remove_dashboard_success'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('message|document_remove_dashboard_fail'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    onRouterAction (element) {
      if (element.text === 'Main' && element._id === null) this.$router.push('/documents')
      else {
        const folder =
        this.folders.find(f => f._id === element._id) ||
        this.companyFolders.find(f => f._id === element._id) ||
        this.groupFolders.find(f => f._id === element._id)
        if (folder) this.$router.push(`${element._id}`)
      }
    },
    folderRouting (folder) {
      if (folder.role === 'main') this.$router.push(`documents/folders/${folder._id}`)
      else this.$router.push(`${folder._id}`)

      // TO DO => horizontal scrollInToView
      // const element = document.getElementById('NAV-TABLE')
      // if (element) {
      //   const index = element.childNodes.length - 1
      //   const lastElement = element.childNodes[index]
      //   const offset = lastElement.offsetLeft * 4
      //   element.scrollLeft = offset
      // }
    },
    async onDropCallback (evnt) {
      // const doc = evnt.item._underlying_vm_
      // const folderId = evnt.originalEvent.target.id
      this.dragArea = []
      const doc = evnt.item._underlying_vm_
      const folderId = evnt.originalEvent.toElement.id
      const _id = this.account._id
      const payload = {
        docId: doc._id,
        destinationFolderId: folderId,
        sourceFolderId: this.activeFolder || null,
        documentDragedInToFolder: true
      }
      try {
        await this.dragDocumentInToFolder({ _id, payload })
      } catch (error) {
        console.error(error)
      }
    },
    onMoveCallback (evt, originalEvent) {
      this.dragArea = []
      for (const key in evt.to) {
        if (Object.hasOwnProperty.call(evt.to, key)) {
          const element = evt.to[key]
          if (element.constructor.name === 'Sortable') {
            this.dragArea.push(element.options.group.name)
          }
        }
      }
    },
    onDragStart (evnt) {},
    emitter (e) {}
  }
}
